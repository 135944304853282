.card {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin: 16px;
  width: 50vh; /* Set a fixed width for all the cards */
  height: 200px; /* Set a fixed height for all the cards */
  text-align: left;
}

.logo {
  flex: 0 0 80px; /* Increase the logo size to 80px */
  margin-right: 16px; /* Add some spacing between the logo and content */
}

.logo-image {
  display: block;
  width: 100%;
  height: auto;
  filter: grayscale(100%);
  transition: filter 0.3s ease;
}

.card:hover .logo-image {
  filter: grayscale(0%);
}

.content {
  display: flex;
  flex-direction: column;
  width: calc(100% - 96px); /* Adjust the width of the content based on the logo and padding */
}

.card-title {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  color: gray; /* Set the initial title color to gray */
  transition: color 0.3s ease; /* Add transition for color change */
}

.card:hover .card-title {
  color: black; /* Change the title color to black on hover */
}

/* .card-subheading {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  color: #666;
  margin-top: 8px; 
}  */

