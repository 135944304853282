.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }
  
  .contact-info {
    width: 45%;
    padding-right: 5%;
  }
  
  .social-media {
    width: 45%;
    display: flex;
    justify-content: center;
  }
  
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  li {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  
  li svg {
    margin-right: 10px;
    font-size: 1.2rem;
  }
  