.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 100%;
  overflow: hidden;
}

.card-wrapper {
  padding: 10px;
  width: 100%;
}

.vertical-image-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.vertical-image {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
   .card-wrapper {
    flex-basis: 100%;
  }
}
