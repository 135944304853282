/* Header.css */

header {
  background-color: #fff;
  height: 10vh;
  box-shadow: 0 5px 30px rgb(0 22 84 / 10%);
  padding: 15px 0;
  position: sticky;
  top: 0;
  z-index: 99999;
}

header img {
  width: 100px;
}

header a {
  margin-left: 30px;
  transition: 0.5s;
  font-weight: 500;
  text-decoration: none;
  font-size: 16px;
}

header a:hover {
  color: #f08409;
  text-decoration: none;
}

header span {
  width: auto;
  padding: 0px 6px;
  background: #f08409;
  border-radius: 50px;
  color: #ffffff;
  margin-right: 4px;
  font-weight: 400;
}

header h4 {
  font-weight: 500;
  margin-right: 20px;
}

header .toggle {
  display: none;
}

/* Updated styles */
@media screen and (max-width: 768px) {
  header {
    padding: 0;
  }
  header img {
    margin-top: 30px;
  }
  header .button {
    display: none;
  }
  header .nav .flex {
    display: none;
  }
  header .toggle {
    display: block;
    margin-top: 20px;
  }
  header .small {
    position: absolute;
    top: 10vh;
    left: 0;
    width: 100%;
    background-color: #f08409;
    padding: 20px;
  }

  header .small .active {
    color: #000; /* Change the color to black for the selected mobile navigation item */
  }

  header li a {
    display: inline-block;
    margin-bottom: 20px;
    color: #fff;
    font-size: 16px;
    text-decoration: none;
  }

  /* Updated styles for selected mobile navigation item */
  header .small li a {
    color: #fff; /* Change the color to black */
  }
}
