.institution-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 20px;
  }
  
  .logo-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
  }
  
  .logo {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  .details-container {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
  }
  
  .institution-name {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .institution-description {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .institution-location {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .institution-container {
      flex-direction: column;
    }
    
    .logo-container {
      margin: 0;
    }
    
    .details-container {
      margin: 0;
    }
  }
  