.hero {
  background-image: url("../../../../public/images/banner.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 45vh;
  width: 100%;
}

.hero .container {
  padding-top: 5%;
}

.hero h1 {
  color: #fff;
  font-size: 60px;
}

.hero p {
  color: #fff;
  opacity: 0.8;
}

input::placeholder {
  font-size: 17px;
  color: black;
}

@media screen and (max-width: 800px) {
  .hero .container {
    padding-top: 30%;

  }

  .hero {
    height: 55vh;
  }

  .hero h1 {
    font-size: 40px;
  }
}
