.slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    max-width: 600px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    margin: 20px auto;
  }
  
  .slider-nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
  }
  
  .slider-prev,
  .slider-next {
    border: none;
    background-color: transparent;
    font-size: 1.5rem;
    color: #333;
    cursor: pointer;
    padding: 5px;
  }
  
  .slider-prev:hover,
  .slider-next:hover {
    color: #999;
  }
  
  .slider-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    padding: 20px;
    background-color: #fff;
  }
  
  .slider-image {
    width: 150px;
    height: 150px;
    margin-bottom: 10px;
  }
  
  .slider-image img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .slider-text {
    text-align: center;
  }
  
  .slider-text h2 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .slider-text p {
    margin: 0;
    font-size: 1rem;
  }
  
  
   