.card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  
  .card-wrapper {
    padding: 10px;
  }
  
  .vertical-image-container {
    display: flex;
    justify-content: flex-end;
  }
  
  .vertical-image {
    width: 100%;
  }
  