det {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
  }
  
  h1 {
    color: #ff6922;
    font-size: 2em; /* adjust size as needed */
    text-align: center;
  }

  p {
    padding: 20px; /* set default padding */
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 1.5em;
    }
    p {
        padding: 10px; 
      }
  }
  
  @media (max-width: 480px) {
    h1 {
      font-size: 1em;
    }
    p {
        padding: 5px; 
      }
  }
  