.about {
    margin-bottom: 80px;
  }
  .about .heading {
    text-align: left;
    width: 100%;
    margin-bottom: 30px;
  }
  .about p {
    font-size: 15px;
    padding-right: 30px;
    margin-bottom: 20px;
    line-height: 30px;
  }
  @media screen and (max-width: 768px) {
    .about .about-container {
      flex-direction: column;
    }
    .about p {
      padding: 0;
    }
    .about .about-container img {
      margin-top: 50px;
    }
  }